.update-banner-button {
    width: 100%; 
    margin: 0.5rem !important; 
}
.update-banner-button:hover {
    background-color: #1976d2;
}

.delete-button {
    font-size: 2rem !important;
}